import { Injectable } from '@angular/core';
import { Apollo, gql } from 'apollo-angular';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { GranularityEnum } from '../models';
import {
  ApplicationsWithAvailabilityList,
  Availability,
} from './availability.models';

export const imports = [];

interface AvailabilityVariables {
  filter: {
    from: string;
    until: string;
    unit: string;
    appNames: string[];
    timeZone: string;
  };
}

const GET_AVAILABILITY_DATA = gql`
  query GetAvailability($filter: AvailabilityFilterGraphqlParameterInput!) {
    applicationsWithAvailability(parameter: $filter) {
      errorMessages {
        message
      }
      applicationsWithAvailability {
        application {
          description
          name
        }
        percentagesAtTimeStamps {
          timeStamp
          percentage
        }
      }
    }
  }
`;

@Injectable({
  providedIn: 'root',
})
export class AvailabilityService {
  constructor(private apollo: Apollo) {}

  getAvailability(
    fromDate: Date,
    toDate: Date,
    appNames: string[],
    unit: string | null,
  ): Observable<ApplicationsWithAvailabilityList | undefined> {
    if (!fromDate) {
      fromDate = new Date(new Date().setMonth(new Date().getMonth() - 1));
    }
    if (!toDate) {
      toDate = new Date();
    }
    if (!appNames) {
      appNames = [];
    }
    if (!unit) {
      unit = GranularityEnum.Days;
    }

    const variables: AvailabilityVariables = {
      filter: {
        from: fromDate.toISOString(),
        until: toDate.toISOString(),
        unit: unit,
        appNames: appNames,
        timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      },
    };

    return this.apollo
      .watchQuery<Availability>({
        query: GET_AVAILABILITY_DATA,
        variables: variables,
        fetchPolicy: 'cache-and-network',
      })
      .valueChanges.pipe(map((res) => res.data.applicationsWithAvailability));
  }
}
