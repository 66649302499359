import { NotificationMessage } from '@paldesk/shared-lib/data-access/notifications-generated';
import { DateUtils } from '@paldesk/shared-lib/utils/date-utils';
import { Alert, NotificationCard } from '../models';
import { DatePipe } from '@angular/common';
import { inject } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

let translateService: TranslateService;
let datePipe: DatePipe;

export function initializeServices(): void {
  translateService = inject(TranslateService);
  datePipe = inject(DatePipe);
}

export function filterHistoryNotifications(
  messages: NotificationMessage[],
  startDate: Date | null | undefined,
  endDate: Date | null | undefined,
): NotificationMessage[] {
  let filteredMessages = Array.isArray(messages) ? messages : [];
  if (startDate && endDate) {
    filteredMessages = filteredMessages.filter((message) =>
      filterNotifications(message, {
        startDate: startDate,
        endDate: endDate,
      }),
    );
  }
  return filteredMessages;
}

export function filterNotifications(
  notification: NotificationMessage,
  filter: {
    startDate: Date;
    endDate: Date;
  },
): boolean | null {
  const startDate = notification.start_date
    ? new Date(notification.start_date)
    : null;
  const expirationDate = notification.expiration_date
    ? new Date(notification.expiration_date)
    : null;

  const filterStartTime = filter.startDate.getTime();
  const filterEndTime = getEndOfTheDay(filter.endDate);

  if (!startDate && !expirationDate) {
    // When both start and end date are null, show on current incidents
    return true;
  }

  if (!startDate) {
    // When only start date is null, show on current or past incidents depending on end date
    return (
      expirationDate &&
      expirationDate.getTime() >= filterStartTime &&
      expirationDate.getTime() <= filterEndTime.getTime()
    );
  }

  if (!expirationDate) {
    // When only end date is null, show on current incidents or not shown at all depending on start date
    return startDate.getTime() <= filterEndTime.getTime();
  }

  // When both dates are set, show on current or past incidents depending on dates
  return DateUtils.isInRange(
    startDate.getTime(),
    expirationDate.getTime(),
    filterStartTime,
    filterEndTime.getTime(),
  );
}

export function getEndOfTheDay(date: Date): Date {
  const endOfDay = new Date(date);
  endOfDay.setHours(23, 59, 59, 999);
  return endOfDay;
}

export function getAlert(message: NotificationMessage): Alert {
  const informationLevel = 'INFORMATION'; //Maintenance level

  if (message.level === informationLevel) {
    return {
      text: message.text,
      title: null,
      downtime: null,
    };
  } else {
    let dateText: string;
    let downtimeText: string | null = null;

    dateText = `${translateService.instant('status_paldesk.issue.started')}`;
    if (message.start_date) {
      dateText += ` ${datePipe.transform(
        new Date(message.start_date),
        'short',
      )}`;
    } else {
      dateText += `${translateService.instant(
        'status_paldesk.issue.under_investigation',
      )}`;
    }
    dateText +=
      ' - ' + `${translateService.instant('status_paldesk.resolved.title')}`;
    if (
      message.start_date &&
      message.expiration_date &&
      new Date(message.expiration_date) <= new Date()
    ) {
      dateText += `${datePipe.transform(
        new Date(message.expiration_date),
        'short',
      )}`;
      downtimeText = calculateDownTimeText(
        new Date(message.start_date),
        new Date(message.expiration_date),
      );
    } else {
      dateText += `${translateService.instant(
        'status_paldesk.issue.not_resolved',
      )}`;
    }
    return {
      title: dateText,
      downtime: downtimeText !== '' ? downtimeText : null,
      text: message.text,
    };
  }
}

export function createNotificationCards(
  messages: NotificationMessage[],
): NotificationCard[] {
  return messages.map((notification) => ({
    id: notification.id,
    level: notification.level ?? null,
    title: notification.title ?? null,
    link: notification.link ?? null,
    content: getAlert(notification),
    applicationCategories: notification.application_categories ?? [],
  }));
}

export function calculateDownTimeText(
  startDate: Date,
  expirationDate: Date,
): string {
  const diffMilliseconds = expirationDate.getTime() - startDate.getTime();
  const diffMinutes = Math.floor(diffMilliseconds / 1000 / 60);
  const downtimeText = `${translateService.instant(
    'status_paldesk.issue.downtime',
  )}`;

  const result: string[] = [];
  result.push(downtimeText);
  if (diffMinutes >= 1440) {
    const days = Math.floor(diffMinutes / 1440);
    result.push(
      `${days} ${translateService.instant(
        'status_paldesk.granularity_filter.days',
      )}`,
    );
  }

  const hours = Math.floor((diffMinutes % 1440) / 60);
  if (hours > 0) {
    result.push(
      `${hours} ${translateService.instant(
        'status_paldesk.granularity_filter.hours',
      )}`,
    );
  }

  const minutes = diffMinutes % 60;
  if (minutes > 0) {
    result.push(
      `${minutes} ${translateService.instant(
        'status_paldesk.granularity_filter.minutes',
      )}`,
    );
  }
  return result.join(' ');
}
