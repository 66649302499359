import {
  ApplicationCategory,
  NotificationMessage,
} from '@paldesk/shared-lib/data-access/notifications-generated';

export interface NotificationCard {
  id: number;
  level: string | null; // Make level non-optional
  title: string | null; // Make title non-optional
  link: string | null; // Make link non-optional
  content: Alert;
  applicationCategories: ApplicationCategory[];
}

export interface Alert {
  title: string | null | undefined; // Make title non-optional
  text: string | null | undefined; // Make text non-optional
  downtime: string | null | undefined; // Make downtime non-optional
}

export enum NotificationsType {
  Current = 0,
  History = 1,
}

export interface PastIncidentsDto {
  messages: Array<NotificationMessage>;
  startDate: Date;
  endDate: Date;
}

export enum GranularityEnum {
  Months = 'Months',
  Days = 'Days',
  Hours = 'Hours',
}

export interface PastIncidentsFilter {
  startDate: Date | null | undefined;
  endDate: Date | null | undefined;
  appFilter: string[] | undefined;
  selectedGranularity: GranularityEnum | undefined;
}
