import { computed, DestroyRef, inject, Injectable } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { CallState } from '@config';
import {
  patchState,
  signalStore,
  type,
  withComputed,
  withHooks,
  withMethods,
  withState,
} from '@ngrx/signals';
import {
  addEntities,
  setAllEntities,
  withEntities,
} from '@ngrx/signals/entities';
import { rxMethod } from '@ngrx/signals/rxjs-interop';
import { TranslateService } from '@ngx-translate/core';
import { NotificationService } from '@paldesk/shared-lib/data-access/notifications-generated';
import {
  catchError,
  combineLatest,
  debounceTime,
  NEVER,
  Observable,
  switchMap,
  tap,
} from 'rxjs';
import {
  ApplicationsWithAvailabilityList,
  AvailabilityInfluencers,
} from '../backend/availability.models';
import { AvailabilityService } from '../backend/availability.service';
import {
  GranularityEnum,
  NotificationCard,
  PastIncidentsFilter,
} from '../models';
import {
  calculateAvailabilityInfluencers,
  setGranularity,
} from './availability-utils';
import {
  createNotificationCards,
  filterHistoryNotifications,
  getEndOfTheDay,
  initializeServices,
} from './notification-utils';

interface StatusPageState {
  availabilities: ApplicationsWithAvailabilityList | undefined;
  top5: AvailabilityInfluencers | undefined;
  filter: PastIncidentsFilter | undefined;
  availabilitiesCallState: CallState;
  notificationsCallState: CallState;
  notificationsHistoryCallState: CallState;
}

const InitialState: StatusPageState = {
  availabilities: undefined,
  top5: undefined,
  filter: undefined,
  availabilitiesCallState: 'init',
  notificationsCallState: 'init',
  notificationsHistoryCallState: 'init',
};

@Injectable({
  providedIn: 'root',
})
export class StatusPageStore extends signalStore(
  withState(InitialState),
  withEntities({
    entity: type<NotificationCard>(),
    collection: 'notifications',
  }),
  withEntities({
    entity: type<NotificationCard>(),
    collection: 'notificationsHistory',
  }),
  withComputed((state) => {
    const translateService = inject(TranslateService);
    const unknownText = translateService.instant(
      'status_paldesk.availability_chart.unknown',
    );

    function getErrorState(name: string) {
      return typeof state[name]() === 'object' ? state[name]().error : null;
    }

    const availabilitiesLoading = computed(
      () => state.availabilitiesCallState() === 'loading',
    );
    const availabilitiesLoaded = computed(
      () => state.availabilitiesCallState() === 'loaded',
    );
    const availabilitiesError = computed(() =>
      getErrorState('availabilitiesCallState'),
    );

    const notificationsLoading = computed(
      () => state.notificationsCallState() === 'loading',
    );
    const notificationsLoaded = computed(
      () => state.notificationsCallState() === 'loaded',
    );
    const notificationsError = computed(() =>
      getErrorState('notificationsCallState'),
    );

    const notificationsHistoryLoading = computed(
      () => state.notificationsHistoryCallState() === 'loading',
    );
    const notificationsHistoryLoaded = computed(
      () => state.notificationsHistoryCallState() === 'loaded',
    );
    const notificationsHistoryError = computed(() =>
      getErrorState('notificationsHistoryCallState'),
    );

    const apps = computed(() => {
      const availabilities = state.availabilities();
      if (!availabilities || !availabilities.applicationsWithAvailability) {
        return [];
      }

      const sortedApplications = [
        ...availabilities.applicationsWithAvailability,
      ]?.sort((a, b) => {
        if (a.application?.name && b.application?.name) {
          return a.application?.name.localeCompare(b.application?.name);
        }
        return -1;
      });

      return (
        sortedApplications?.map((data) => ({
          value: data.application?.name ?? unknownText,
          viewValue: data.application?.name ?? unknownText,
        })) ?? []
      );
    });

    const top5 = computed(() => {
      if (state.availabilities() && apps() && state.filter()?.appFilter) {
        return calculateAvailabilityInfluencers(
          state.availabilities(),
          apps(),
          state.filter()?.appFilter,
          unknownText,
        );
      }
      return { overallAvailabilitiesRankedWorstToBest: [], isVisible: false };
    });

    return {
      apps,
      top5,
      availabilitiesLoading,
      availabilitiesLoaded,
      availabilitiesError,
      notificationsLoading,
      notificationsLoaded,
      notificationsError,
      notificationsHistoryLoading,
      notificationsHistoryLoaded,
      notificationsHistoryError,
    };
  }),
  withMethods((state) => {
    initializeServices();
    const notificationService = inject(NotificationService);
    const availabilityService = inject(AvailabilityService);
    const destroyRef = inject(DestroyRef);

    function getAvailability(
      filter: Omit<PastIncidentsFilter, 'selectedGranularity'>,
    ) {
      if (filter.startDate && filter.endDate) {
        patchState(state, { availabilitiesCallState: 'loading' });
        const newEndDate = getEndOfTheDay(filter.endDate);
        const granularity = setGranularity(
          filter.startDate,
          newEndDate,
          state.filter()?.selectedGranularity,
        );

        return availabilityService
          .getAvailability(
            filter.startDate,
            newEndDate,
            filter.appFilter || [],
            granularity,
          )
          .pipe(
            takeUntilDestroyed(destroyRef),
            tap((data) => {
              patchState(state, { availabilities: data });
              patchState(state, {
                availabilitiesCallState: 'loaded',
              });
            }),
            catchError((error) => {
              patchState(state, {
                availabilitiesCallState: { error: error.message },
              });
              return NEVER;
            }),
          );
      }

      return NEVER;
    }

    function getPublicNofiticationHistory(filterValue: PastIncidentsFilter) {
      patchState(state, { notificationsHistoryCallState: 'loading' });
      return notificationService.getPublicNotificationsHistory().pipe(
        tap((response) => {
          if (response?.messages) {
            const messages = createNotificationCards(
              filterHistoryNotifications(
                response.messages,
                filterValue.startDate ?? state.filter()?.startDate,
                filterValue.endDate ?? state.filter()?.endDate,
              ),
            );
            patchState(
              state,
              setAllEntities(messages, {
                collection: 'notificationsHistory',
              }),
            );
          }
          patchState(state, { notificationsHistoryCallState: 'loaded' });
        }),
        catchError((error) => {
          patchState(state, {
            notificationsHistoryCallState: { error: error.message },
          });
          return NEVER;
        }),
      );
    }

    return {
      getPublicNotifications: () => {
        patchState(state, { notificationsCallState: 'loading' });
        notificationService
          .getPublicNotifications()
          .pipe(takeUntilDestroyed())
          .subscribe({
            next: (data) => {
              const messages = createNotificationCards(data?.messages || []);
              patchState(
                state,
                addEntities(messages, { collection: 'notifications' }),
              );
              patchState(state, { notificationsCallState: 'loaded' });
            },
            error: (error) => {
              patchState(state, {
                notificationsCallState: { error: error.message },
              });
            },
          });
      },
      onFilterChange: rxMethod<any>((data$: Observable<PastIncidentsFilter>) =>
        data$.pipe(
          takeUntilDestroyed(destroyRef),
          debounceTime(500),
          tap((filter: PastIncidentsFilter) => {
            patchState(state, {
              filter: {
                startDate: filter.startDate,
                endDate: filter.endDate,
                appFilter: filter.appFilter,
                selectedGranularity: filter.selectedGranularity,
              },
            });
          }),
          switchMap((filter) =>
            combineLatest([
              getPublicNofiticationHistory(filter),
              getAvailability(filter),
            ]),
          ),
        ),
      ),
      initFilter: () => {
        const initialEndDate = new Date();
        initialEndDate.setDate(initialEndDate.getDate());
        initialEndDate.setHours(0, 0, 0, 0);
        const initialStartDate = new Date(initialEndDate);
        initialStartDate.setMonth(initialStartDate.getMonth() - 1);

        patchState(state, {
          filter: {
            startDate: initialStartDate,
            endDate: initialEndDate,
            appFilter: [],
            selectedGranularity: GranularityEnum.Days,
          },
        });
      },
      getAvailability,
      getPublicNofiticationHistory,
    };
  }),
  withHooks({
    onInit(state) {
      state.initFilter();
      state.getPublicNotifications();
      state.onFilterChange(state.filter());
    },
  }),
) {}
