import {
  ApplicationsWithAvailabilityList,
  OverallAppAvailability,
} from '../backend/availability.models';
import { FilterValue } from '@design-system/components/filter-input';
import { GranularityEnum } from '../models';

export function setGranularity(
  startDate: any,
  endDate: any,
  selectedGranularity: GranularityEnum | undefined,
): GranularityEnum {
  const dateDiffInDays = (endDate - startDate) / (1000 * 60 * 60 * 24);

  let granularity;
  switch (true) {
    case dateDiffInDays < 1:
      // if the date range is less than 1 day, only granularity available is hourly
      granularity = GranularityEnum.Hours;
      break;
    case dateDiffInDays >= 1 && dateDiffInDays <= 2:
      // if the date range is between 1 and 2 days, available granularities are hourly and daily
      granularity = selectedGranularity;
      break;
    case dateDiffInDays > 2 && dateDiffInDays < 60:
      // if the date range is more than 2 days and less than 60 days, only granularity available is daily
      granularity = GranularityEnum.Days;
      break;
    case dateDiffInDays >= 60:
      // if the date range is 60 days or more, only granularity available is monthly
      granularity = GranularityEnum.Months;
      break;
  }
  return granularity;
}

export function calculateAvailabilityInfluencers(
  availabilities: ApplicationsWithAvailabilityList | undefined,
  apps: FilterValue[] | undefined,
  filter: string[] | undefined,
  unknownText: string,
) {
  let overallAvailabilities: OverallAppAvailability[] = [];

  let isVisible = checkAppsFilterToCalculateAvailabilityInfluencersVisibility(
    apps,
    filter,
  );

  if (apps && isVisible) {
    overallAvailabilities = calculateAppAvailability(
      availabilities,
      unknownText,
    );

    overallAvailabilities = overallAvailabilities.filter(
      (app) => app.availability < 100,
    );
    if (overallAvailabilities.length === 0) {
      // handle edge case when all apps are 100% available
      isVisible = false;
    } else {
      overallAvailabilities.sort((a, b) => a.availability - b.availability);
    }
  }
  return {
    overallAvailabilitiesRankedWorstToBest: overallAvailabilities,
    isVisible: isVisible,
  };
}

export function calculateOverallAvailability(
  percentageValues: number[],
): number {
  if (percentageValues.length > 0) {
    return (
      percentageValues.reduce(
        (accumulator, currentValue) => accumulator + currentValue,
        0,
      ) / percentageValues.length
    );
  } else {
    return 0;
  }
}

export function calculateAppAvailability(
  apps: ApplicationsWithAvailabilityList | undefined,
  unknownText: string,
): OverallAppAvailability[] {
  const overallAvailabilities: OverallAppAvailability[] = [];
  apps?.applicationsWithAvailability?.forEach((app) => {
    if (app.percentagesAtTimeStamps && app.application) {
      const percentageValues: number[] = [];
      app.percentagesAtTimeStamps.forEach((percentageAtTimeStamp) => {
        if (
          percentageAtTimeStamp.percentage !== null &&
          percentageAtTimeStamp.percentage !== undefined
        ) {
          percentageValues.push(percentageAtTimeStamp.percentage);
        }
      });

      const overallAppAvailability: OverallAppAvailability = {
        name: app.application.name ?? unknownText,

        availability: calculateOverallAvailability(percentageValues),
      };

      overallAvailabilities.push(overallAppAvailability);
    }
  });
  return overallAvailabilities;
}

export function checkAppsFilterToCalculateAvailabilityInfluencersVisibility(
  apps: FilterValue[] | undefined,
  filter: string[] | undefined,
): boolean {
  return !filter || filter.length === 0 || filter.length === apps?.length;
}
